import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Html5QrcodeScanner } from "html5-qrcode";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

//import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

export default class FeeManager extends Component {
	
	constructor(props){
        super(props)
		this.state = { 
			country: '',
			states: [],
			cusTypeName: '',
			payers:[],
			parameters:[],
			payerStatesArray:[],
			templates:[],
			payerTypes:[],
			payerLocation:[],
			payersDetails:[],
			templateHeaderParms:[],
			templateColumnParms:[],
			addEditType:false,
			addEditParms:false,
			showTempArea:false,
			showTempGen:false,
			effectiveDate:'',
			tempHeaders:new Map(),
			tempColumns:new Map(),
		};
    }
	
	componentDidMount() {
		this.loadScript();
		this.getPayers();
		this.getPayerTypes();
		this.getPayerLocations();
		this.getParameters();
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditType'){
			let selectedTypeName = event.target.selectedOptions[0].text;
			this.setState({addEditType:false,wTypeName:selectedTypeName});
		}
		
		if(name == 'parms'){
			let selectedParmsName = event.target.selectedOptions[0].text;
			this.setState({addEditParms:false,wParmsName:selectedParmsName});
		}
		
		if(name == 'payer'){
			let payers = this.state.payers;
			let selectedTypeName = event.target.selectedOptions[0].text;
			this.setState({cusPayerName:selectedTypeName});
			payers.map((val, i) => {
				if(val['id'] == value){
					this.state.payerLocation.map((vl, i) => {
						if(vl['id'] == val['country']){
							this.setState({states: vl['states'] ? vl['states'] : [] });
						}
					});
					this.setState({payerCountry:val['country'],payerStates:val['states'],payerStatesArray:val['states'].split(','),selectpayerType:val['type'] });
				}
			});
		}
		
		if(name == 'selectedTemplate'){
			let templates = this.state.templates;
			let selectedtemplateName = event.target.selectedOptions[0].text;
			this.setState({templateName:selectedtemplateName});
			let tempHeaders = this.state.tempHeaders;
			let tempColumns = this.state.tempColumns;
			templates.map((val, i) => {
				if(val['id'] == value){
					let parameters = val.parameters;
					let headers = [];
					let columns = [];
					parameters.map((vl, i) => {
						if(vl['type'] == 'header'){
							headers.push(vl);
							tempHeaders.set(vl['id'],vl);
						}
						if(vl['type'] == 'column'){
							columns.push(vl);
							tempColumns.set(vl['id'],vl);
						}
					});
					this.setState({templateHeaderParms:headers,templateColumnParms:columns,tempHeaders,tempColumns});
				}
			});
		}
		
		if(name == 'selectedTemplate' && value == ''){
			this.setState({selectedTemplate:'',templateName:'',templateHeaderParms:[],templateColumnParms:[],tempHeaders:new Map(),tempColumns:new Map()});
		}
	}
	
	selectCountry=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let selectedTypeName = event.target.selectedOptions[0].text;
		let countries = this.state.payerLocation
		//console.log('countries->',countries);
		countries.map((val, i) => {
			if(val['id'] == res){
				this.setState({states: val['states'] ? val['states'] : [] });
			}
		});
		
		this.setState({addEditLoc:false,wLocName:selectedTypeName});
	}
	
	selectCountryAss(val) {
		this.setState({ countryAss: val });
	}

	selectRegion=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let selectedStateName = event.target.selectedOptions[0].text;
		res = Array.from(event.target.selectedOptions, (item) => item.value).join();
		let newArray = name+'Array';
		this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value),addEditState:false,wStateName:selectedStateName});
		this.setState({addEditState:false,wStateName:selectedStateName});
	}
	
	getPayers=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payers';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payers:data});
        })
	}
	
	getParameters=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'parameters';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({parameters:data});
        })
	}
	
	getPayerTypes=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payer-types';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payerTypes:data});
        })
	}
	
	getPayerLocations=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'countries';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payerLocation:data});
			let payerLoc = this.state.payerLoc;
			if(payerLoc){
			data.map((val, i) => {
				if(val['id'] == payerLoc){
					this.setState({states: val['states'] ? val['states'] : [] });
				}
			});
			}
        })
	}
	
	addPayerType=() => {
		this.setState({addEditType:true,cusTypeName:'',editTypeId:''});
	}
	
	editPayerType=() => {
		let typeName = this.state.wTypeName;
		this.setState({addEditType:true,cusTypeName:typeName,editTypeId:this.state.wEditType});
	}
	
	addPayerLoc=() => {
		this.setState({addEditLoc:true,cusCntryName:'',editLocId:'',cusStateName:''});
	}
	
	editPayerLoc=() => {
		let wLocName = this.state.wLocName;
		this.setState({addEditLoc:true,cusCntryName:wLocName,editLocId:this.state.payerLoc,cusStateName:''});
	}
	
	addUpdateType=() => {
		
		let cusTypeName = this.state.cusTypeName;
		let editTypeId = this.state.editTypeId;
		if(cusTypeName || editTypeId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer-type';
			let formData = new FormData();
			formData.append('id', editTypeId);
			formData.append('type', cusTypeName);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getPayerTypes();
					this.setState({cusTypeName:'',addEditType:false,wTypeName:'',editTypeId:''});
					$('.w-edit-category').val('');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteType=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-payer-type/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerTypes();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select payer type!');
		}
	}
	
	setWorkflowList=() => {
	}
	
	addPayerLocState=() => {
		this.setState({editLocId:this.state.payerLoc,addEditState:true,cusStateName:'',editStateId:''});
	}
	
	editPayerLocState=() => {
		let wStateName = this.state.wStateName;
		this.setState({editLocId:this.state.payerLoc,addEditState:true,cusStateName:wStateName,editStateId:this.state.locState});
	}
	
	deleteCountry=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-country/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select Country!');
		}
	}
	
	deleteState=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-state/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select State!');
		}
	}
	
	addUpdateLocation=() => {
		
		let cusCntryName = this.state.cusCntryName;
		let editLocId = this.state.editLocId;
		
		let cusStateName = this.state.cusStateName;
		let editStateId = this.state.editStateId;
		
		if(cusCntryName || cusStateName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer-location';
			let formData = new FormData();
			if(cusStateName){
				formData.append('country_id', editLocId);
				formData.append('state_id', editStateId ? editStateId : '');
				formData.append('name', cusStateName);
			}else{
				formData.append('id', editLocId);
				formData.append('name', cusCntryName);
			}
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.setState({cusCntryName:'',addEditLoc:false,wLocName:'',editLocId:'',addEditState:false,locState:'',cusStateName:'',editStateId:''});
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addUpdatePayer=() => {
		let payer = this.state.payer;
		let payerCountry = this.state.payerCountry;
		let payerStates = this.state.payerStates.split(',');
		//let payerStatesArray = this.state.payerStates.split(',');
		let selectpayerType = this.state.selectpayerType;
		let cusPayerName = this.state.cusPayerName;
		
		if(cusPayerName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer';
			let formData = new FormData();
			if(payer){
				formData.append('id', payer);
			}
			formData.append('name', cusPayerName);
			formData.append('country', payerCountry);
			formData.append('states', payerStates);
			formData.append('type', selectpayerType);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.setState({payer:'',payerCountry:'',payerStates:'',payerStatesArray:[],selectpayerType:'',cusPayerName:''});
					this.getPayers();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addParms=() => {
		this.setState({addEditParms:true,cusParmsName:'',editParmsId:''});
	}
	
	editParms=() => {
		let wParmsName = this.state.wParmsName;
		this.setState({addEditParms:true,cusParmsName:wParmsName,editParmsId:this.state.parms});
	}
	
	addUpdateParms=() => {
		this.updateParmsOrder();
		let cusParmsName = this.state.cusParmsName;
		let editParmsId = this.state.editParmsId;
		if(cusParmsName || editParmsId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-parameter';
			let formData = new FormData();
			formData.append('id', editParmsId);
			formData.append('name', cusParmsName);
			formData.append('parameters', this.state.parameters);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getParameters();
					this.setState({parms:'',cusParmsName:'',addEditParms:false,wParmsName:'',editParmsId:''});
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateParmsOrder=() => {
		let parameters = this.state.parameters;
		let newArray = [];
		parameters.map((val, i) => {
			newArray.push(val['id']);
		})
		let ids = newArray.join();
		if(parameters){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-parm-order';
			let formData = new FormData();
			formData.append('parameters', ids);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getParameters();
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteParms=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-parms/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getParameters();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select the Parameter!');
		}
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.setState(({parameters}) => ({
		    parameters: arrayMove(parameters, oldIndex, newIndex),
		}));
	};
	
	onSortEndHeader = ({oldIndex, newIndex}) => {
		this.setState(({templateHeaderParms}) => ({
		    templateHeaderParms: arrayMove(templateHeaderParms, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let move = [];
			that.state.templateHeaderParms.map((val, i) => {
				val.pos = i+1;
				move.push(val);
			});
			that.setState({templateHeaderParms:move});
		}, 1000);
		 
	};
	
	onSortEndColumn = ({oldIndex, newIndex}) => {
		this.setState(({templateColumnParms}) => ({
		    templateColumnParms: arrayMove(templateColumnParms, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let move2 = [];
			that.state.templateColumnParms.map((val, i) => {
				val.pos = i+1;
				move2.push(val);
			});
			that.setState({templateColumnParms:move2});
		}, 1000);
	};
	
	templateManager=() => {
		this.getTemplates();
		this.setState({showTempArea:true,showTempGen:false});
	}
	
	setTempHeaders = (event) => {
		let parmId = parseInt(event.target.id);
		let taskName = event.target.name;
		let length = this.state.templateHeaderParms.length;
		let tempHeaders = this.state.tempHeaders;
		if(event.target.checked){
			this.state.parameters.map((val, i) => {
				if(val.id == parmId){
					val.pos = length+1;
					val.type = 'header';
					tempHeaders.set(parmId,val); 
				}
			});
		}else{
			tempHeaders.delete(parmId);
		}
		
		this.setState({tempHeaders:tempHeaders});
		
		let opTasks = Object.fromEntries(tempHeaders.entries());
		let templateParms = [];
		
		for (let prop in opTasks) {
			templateParms.push(opTasks[prop]);
		}
		templateParms = templateParms.filter(function(x) {
			 return x !== '';
		});
		
		templateParms.sort(function(a, b){
			return parseInt(a.pos)- parseInt(b.pos);
		});
		
		//console.log('templateParms->',templateParms);
		
		templateParms.map((val, i) => {
			val.pos = i+1;
		});
		this.setState({templateHeaderParms:templateParms});
	}
	
	setTempColumns = (event) => {
		let parmId = parseInt(event.target.id);
		let length = this.state.templateColumnParms.length;
		let tempColumns = this.state.tempColumns;
		
		if(event.target.checked){
			this.state.parameters.map((val, i) => {
				if(val.id == parmId){
					val.pos = length+1;
					val.type = 'column';
					tempColumns.set(parmId,val);
				}
			});
		}else{
			tempColumns.delete(parmId);
		}
		
		this.setState({tempColumns:tempColumns});
		
		let opParms = Object.fromEntries(tempColumns.entries());
		let templateParms2 = [];
		
		for (let prop in opParms) {
			templateParms2.push(opParms[prop]);
		}
		templateParms2 = templateParms2.filter(function(x) {
			 return x !== '';
		});
		
		templateParms2.sort(function(a, b){
			return parseInt(a.pos)- parseInt(b.pos);
		});
		
		templateParms2.map((val, i) => {
			val.pos = i+1;
		});
		
		this.setState({templateColumnParms:templateParms2});
	}
	
	getTemplates=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'templates';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({templates:data});
        })
	}
	
	saveGuideTemplate=() => {
		let selectedTemplate = this.state.selectedTemplate;
		let templateName = this.state.templateName;
		let templateHeaderParms = this.state.templateHeaderParms;
		let templateColumnParms = this.state.templateColumnParms;
		
		if(templateName || selectedTemplate){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-template';
			let formData = new FormData();
			
			if(selectedTemplate){
				formData.append('id', selectedTemplate);
			}
			let params = templateHeaderParms.concat(templateColumnParms);
			formData.append('name', templateName);
			formData.append('params', JSON.stringify(params));
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getTemplates();
					//this.setState({parms:'',cusParmsName:'',addEditParms:false,wParmsName:'',editParmsId:''});
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	templateGenerator=() => {
		this.getTemplates();
		this.setState({showTempGen:true,showTempArea:false});
	}
	
	handleChangeStart = date => {
        this.setState({
          effectiveDate: date,date
        });
    }
	
	getPayerDetails=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payer-details/'+value;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payersDetails:data});
        })
	}
	
	downloadGuideTemplate=() => {
		let sTemplate = this.state.sTemplate;
		let guideYear = this.state.guideYear;
		let effectiveDate = this.state.effectiveDate;
		let namefeeGuide = this.state.namefeeGuide;
		let templates = this.state.templates;
		
		let payersDetails = this.state.payersDetails;
		
		let tempHeaders = [];
		let tempColumns = [];
		templates.map((val, i) => {
			if(val['id'] == sTemplate){
				let parameters = val.parameters;
				parameters.map((vl, i) => {
					if(vl['type'] == 'header'){
						tempHeaders.push(vl['name']);
					}
					if(vl['type'] == 'column'){
						tempColumns.push(vl['name']);
					}
				});
			}
		});
		

		let headers = [];
		headers.push(["Payer Name",payersDetails['name']]);
		if(tempHeaders.length > 0){
			tempHeaders.map((hr, i) => {
				if(hr == 'Country'){
					let str = ["Country",payersDetails['country_name']];
					headers.push(str);
				}
				if(hr == 'State / Province'){
					let str = ["State / Province",payersDetails['states_name']];
					headers.push(str);
				}
				if(hr == 'Payer Type'){
					let str = ["Payer Type",payersDetails['payer_type']];
					headers.push(str);
				}
			});
		}
		
		
		/* const header2 = {
			"Fee Guide Year": guideYear,
			"Fee Guide effective Date": moment(effectiveDate).format('MM-DD-YYYY'),
			"Active": 'No',
			"Fee Guide Name": namefeeGuide,
			"Fee Guide ID": '1001',
		}
		
		const mergedObj = Object.assign({}, header, header2);

		console.log('mergedObj->',mergedObj);
		 */
		//console.log('headers->',headers);
		//console.log('tempColumns->',tempColumns);
		
		let fileName = '1001';
		let fieldData = [
			["Fee Guide Year",guideYear],
			["Fee Guide effective Date",moment(effectiveDate).format('MM-DD-YYYY')],
			["Active",'No'],
			["Fee Guide Name",namefeeGuide],
			["Fee Guide ID",'1001'],
			["",""],
			tempColumns,
		];
		
		const tempData = headers.concat(fieldData);
		
		this.newDownloadExcel(tempData,fileName);
		
	}
	
	newDownloadExcel=(tempData,fileName) => {
		
		if(tempData){
			const worksheet = XLSX.utils.aoa_to_sheet(tempData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
			const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
			saveAs(blob, `${fileName}.xlsx`);
		}
    }
	
	render() {

		const { country,region,payers,payerTypes,addEditType,cusTypeName,payerLocation,states,payerStatesArray,parameters,showTempArea,showTempGen,templateHeaderParms,templateColumnParms,tempHeaders,tempColumns,templates} = this.state;
		//console.log('templateHeaderParms->',templateHeaderParms);
		//console.log('templateColumnParms->',templateColumnParms);
		let that=this;
		let href = window.location.href.split('?')[0];
		
		let payersHtml = payers.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let parametersHtml = parameters.map(function(val,i) {
			if(val.id != 1 && val.id != 2 && val.id != 3){
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			}
		})
		
		let payerTypesHtml = payerTypes.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.type}</option>
			);
		})
		
		let statesHtml = states.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let payerLocationHtml = payerLocation.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let templateHtml = templates.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let payerTypeAsssHtml = payerTypes.map(function(val,i) {
			return (
				<div><input type="checkbox" name={val.id} id={val.id} onChange={that.setWorkflowList}/> {val.type}</div>
			);
		})
		
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem = sortableElement(({value}) => {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div field-div-2 ' + className +' move-'+value.id}><DragHandle /><span className="input-title task-input-4">{value.name}</span></div>);
			
		});
		
		const SortableItem2 = sortableElement(({value}) => {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><DragHandle /><span className="input-title task-input-4">{value.name}</span></div>);
			
		});
		
		const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		}); 

		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						<span></span>
					</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<h5 className="page-title">Payer Management</h5>
							<div className="left-link-box-new mb-5">
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerLocation" data-dismiss="modal">Payer Location Manager</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerType" data-dismiss="modal">Payer Type Manager</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerTypeAss" data-dismiss="modal">Payer Type Assignment</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payer" data-dismiss="modal">Create/Edit Payer</button>
							</div>
							
							<h5 className="page-title">Guide Management</h5>
							<div className="left-link-box-new">
								<button className="btn btn-header-link" data-toggle="modal" data-target="#feeParameters" data-dismiss="modal">Fee Guide Parameters</button>
								<button className="btn btn-header-link" onClick={()=>this.templateManager()}>Fee Guide Template Manager</button>
								<button className="btn btn-header-link" onClick={()=>this.templateGenerator()}>Fee Guide Template Generator</button>
							</div>
						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="workflow-manager">
					<div className="temp-list">
						<div className="row">
							{showTempArea ?
							<div className="col-md-12 mb-4">
								<h4 className="mb-4">Fee Guide Template Manager</h4>
								<div className="row justify-content-between main-parm-dev">
									<div className="col-md-5">
										<div className="form-group">
											<select className="form-control" name="selectedTemplate" value={this.state.selectedTemplate} onChange={this.getValue}>
												<option value={''}>Select Fee Guide Template</option>
												{templateHtml}
											</select>
										</div>
										<h6> Fee Guide Parameters  </h6>
										
										<div className="parms-div">
											{parameters.map((value,i) =>{
												let className = 'light-yellow';
												if(value.id == 1 || value.id == 2 || value.id == 3){
													className = 'light-green';
												}
													return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><span className="input-title task-input-4">{value.name}</span><div className="parms-left-checkbox"><input type="checkbox" name={value.id} id={value.id} checked={tempHeaders.get(value.id) ? true:false} onChange={this.setTempHeaders}/><input type="checkbox" name={value.id} id={value.id} checked={tempColumns.get(value.id) ? true:false} onChange={this.setTempColumns}/></div></div>);
												})}
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-2">
												<h6> Fee Guide Template Header Data </h6>
												<div className="parms-div-right border">
													{templateHeaderParms ?
														<SortableContainer onSortEnd={this.onSortEndHeader} useDragHandle>
															{templateHeaderParms.map((value, index) => (
															  <SortableItem2 key={index} index={index} value={value} /> 
															))}
														</SortableContainer>
													:null}
												</div>
											</div>
											<div className="col-md-12 mb-2">
												<h6> Fee Guide Template Column Data  </h6>
												<div className="parms-div-right border">
													{templateColumnParms ?
														<SortableContainer onSortEnd={this.onSortEndColumn} useDragHandle>
															{templateColumnParms.map((value, index) => (
															  <SortableItem2 key={index} index={index} value={value} /> 
															))}
														</SortableContainer>
													:null}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-4">
										<div className="row justify-content-center">
											<div className="col-md-4 pr-0">
												<input className="form-control" name="templateName" value={this.state.templateName} type="text" onChange={this.getValue} placeholder="Template Name"/>
											</div>
											<div className="col-auto">
												<button onClick={()=>this.saveGuideTemplate()} type="button" className="mt-0 btn btn-info float-right mr-1" >Save Fee Guide Template</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							:null}
							
							{showTempGen ? 
								<div className="col-md-12 mb-4">
									<h4 className="mb-4">Fee Guide Template Generator</h4>
									
									<div className="row justify-content-between main-parm-dev">
									<div className="col-md-5">
										<div className="form-group">
											<h5 className="mb-4">Create a blank template for a new fee guide</h5>
											<select className="form-control" name="sTemplate" value={this.state.sTemplate} onChange={this.getValue}>
												<option value={''}>Select Fee Guide Template</option>
												{templateHtml}
											</select>
										</div>
										<div className="form-group">
											<h6 className="mb-1">Select Fee Guide Year</h6>
											<select className="form-control" name="guideYear" value={this.state.guideYear} onChange={this.getValue}>
												<option value=''>Select a Year</option>
												<option value='2023'>2023</option>
												<option value='2024'>2024</option>
												<option value='2025'>2025</option>
												<option value='2026'>2026</option>
												
											</select>
										</div>
										
										<div className="form-group">
											<h6 className="mb-1">Fee Guide Effective Date</h6>
											<DatePicker className="date-picker form-control " placeholderText="mm/dd/yyyy" selected={this.state.effectiveDate} onChange={this.handleChangeStart}/>
										</div>
										
										<div className="form-group">
											<h6 className="mb-1">Payer List</h6>
											<select className="form-control" name="sPayer" value={this.state.sPayer} onChange={this.getPayerDetails}>
												<option value={''}>Select Payer</option>
												{payersHtml}
											</select>
										</div>
										<div className="form-group">
											<label>Name of Fee Guide:</label>
											<input className="form-control" name="namefeeGuide" value={this.state.namefeeGuide} type="text" onChange={this.getValue}/>
										</div>
										<div className="col-group">
											<button onClick={()=>this.downloadGuideTemplate()} type="button" className="mt-0 btn btn-info float-right mr-1" >Download Blank Fee Guide Template</button>
										</div>
									</div>
									</div>
									
								</div>
							:null}
							
						</div>
					</div>
				</div>
				
				<div className="modal" id={"feeParameters"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Fee Guide Additional Parameters</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div>
									<label>Parameters:</label>
									<select className="form-control mb-2" name="parms" value={this.state.parms} onChange={this.getValue}>
										<option value={''}>Select Parameter</option>
										{parametersHtml}
									</select>
									
									<span className="w-edit-span" >
									<button onClick={()=>this.addParms()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.parms ?
									<button onClick={()=>this.editParms()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.parms ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this parameter?')) this.deleteParms(this.state.parms) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									
									{ this.state.addEditParms ?
									<div className="new-category-input mb-2">
										<label>Add&Edit Fee Guide Parameter:</label>
										<input className="form-control" name="cusParmsName" value={this.state.cusParmsName} type="text" onChange={this.getValue}/>
									</div>
									:null}
									
								</div>
								
								<div className="parms-div">
									{parameters ?
										<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
											{parameters.map((value, index) => (
											  <SortableItem key={index} index={index} value={value} /> 
											))}
										</SortableContainer>
									:null}
								</div>
								
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateParms()} type="button" className="btn btn-info float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payer"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Create / Edit Payer</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div className="mb-2">
									<label>Payer</label>
									<select className="form-control" name="payer" value={this.state.payer} onChange={this.getValue}>
										<option value={''}>Select Payer</option>
										{payersHtml}
									</select>
								</div>
								<div className="mb-2">
									<label>Country</label>
									<select className="form-control" name="payerCountry" value={this.state.payerCountry} onChange={this.selectCountry}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
								</div>
								{this.state.payerCountry ?
								<div className="mb-2">
									<label>Select State, Provinces or Regions</label>
									<select className="form-control mb-2" name="payerStates" value={this.state.payerStatesArray} onChange={this.selectRegion} multiple>
										<option value={''}>Select State, Provinces or Regions</option>
											{statesHtml}
									</select>
								</div>
								:null}
								<div className="mb-2">
									<label>Payer Type</label>
									<select className="form-control" name="selectpayerType" value={this.state.selectpayerType} onChange={this.getValue}>
										<option value={''}>Select Payer Type</option>
										{payerTypesHtml}
									</select>
								</div>
								
								<div className="mb-2">
									<label>Save or Update Payer Name:</label>
									<input className="form-control" name="cusPayerName" value={this.state.cusPayerName} type="text" onChange={this.getValue}/>
								</div>
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdatePayer()} type="button" className="btn btn-info float-right mr-1" >Save / Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerLocation"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Location Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
							
								<div>
									<div>
									<select className="form-control mb-2" name="payerLoc" value={this.state.payerLoc} onChange={this.selectCountry}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
									<span className="w-edit-span" >
									<button onClick={()=>this.addPayerLoc()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.payerLoc ?
									<button onClick={()=>this.editPayerLoc()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.payerLoc ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this Country?')) this.deleteCountry(this.state.payerLoc) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									</div>
									{ this.state.addEditLoc ?
									<div className="new-category-input mb-2">
										<label>Add&Edit Country:</label>
										<input className="form-control" name="cusCntryName" value={this.state.cusCntryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
									{this.state.payerLoc ?
										<div>
										<select className="form-control mb-2" name="locState" value={this.state.locState} onChange={this.selectRegion}>
											<option value={''}>Select State, Provinces or Regions</option>
											{statesHtml}
										</select>
										<span className="w-edit-span" >
										<button onClick={()=>this.addPayerLocState()} type="button" className="btn"><i className="fa fa-plus"></i></button>
										{this.state.locState ?
										<button onClick={()=>this.editPayerLocState()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
										:null}
										{this.state.locState ?
										<button onClick={() => { if (window.confirm('Are you sure you want to delete this State, Provinces or Regions?')) this.deleteState(this.state.locState) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
										:null}
										</span>
										
										{this.state.addEditState ?
										<div className="new-category-input">
											<label>Add&Edit Select State, Provinces or Regions:</label>
											<input className="form-control" name="cusStateName" value={this.state.cusStateName} type="text" onChange={this.getValue}/>
										</div>
										:null}
										
										</div>
										
									:null}
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateLocation()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerTypeAss"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Type Assignment</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div>
									<label>Select Country:</label>
									<select className="form-control mb-2" name="countryAss" value={this.state.countryAss} onChange={this.selectCountryAss}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
								</div>
								<div>
									<label>Select all payer types available in this country:</label>
									{payerTypeAsssHtml}
								</div>
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateType()} type="button" className="btn btn-info float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerType"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Type Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label>Payer Types:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditType" onChange={this.getValue}>
									<option value={''}>Select Payer Type</option>
									{payerTypesHtml}
								</select>
								<span className="w-edit-span" >
								<button onClick={()=>this.addPayerType()} type="button" className="btn"><i className="fa fa-plus"></i></button>
								
								{this.state.wEditType ?
								<button onClick={()=>this.editPayerType()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
								:null}
								{this.state.wEditType ?
								<button onClick={() => { if (window.confirm('Are you sure you want to delete this type?')) this.deleteType(this.state.wEditType) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
								:null}
								</span>
								</div>
								{ addEditType ?
								<div className="new-category-input">
									<label>Add&Edit Payer Type name:</label>
									<input className="form-control" name="cusTypeName" value={cusTypeName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateType()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);	
	}
}
