import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";
import moment from 'moment';

export default class QrGenerator extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			qrValue:''
		}
    }
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
	}
	
	qrGeneratorCode=()=>{
		let qrName = this.state.qrName;
		let qrValue = this.state.qrValue;
		let sendToSheet = this.state.sendToSheet;
		if(qrValue){
			$('#qrCodePopup').modal('show');
		}
		if(sendToSheet){
			const qrCodeElement = document.getElementById('qr-code-img');
			let that = this;
			html2canvas(qrCodeElement).then((canvas) => {
				const pngUrl = canvas.toDataURL('image/png');
			    that.sendQrUrlSpreadsheet(qrName,qrValue,pngUrl);
			});
		}
	}
	
	sendQrUrlSpreadsheet=(qrName,qrValue,pngUrl)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'send-to-spreadsheet';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let currentDate = moment(date).format('MM-DD-YYYY HH:mm:ss');
		let formData = new FormData();
		formData.append('qrName', qrName);
		formData.append('qrValue', qrValue);
		formData.append('pngUrl', pngUrl);
		formData.append('date', currentDate);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
        .then(response => {
            let data = response.data;
			
        })
	}
	
	copyImageToClipboard = () => {
		const qrCodeElement = document.getElementById('qr-code-img');
		html2canvas(qrCodeElement).then((canvas) => {
		    const pngUrl = canvas.toDataURL('image/png');
		    const image = new Image();
		    image.src = pngUrl;
		    copyImageToClipboard(pngUrl).then(() => {
			}).catch((e) => {
			  console.log('Error: ', e.message);
			})
		});
	};
	  
	handleCopyQRCode = (fileName) => {
		const qrCodeElement = document.getElementById('qr-code-img');
		if (qrCodeElement) {
		  html2canvas(qrCodeElement).then((canvas) => {
			const image = canvas.toDataURL('image/png');
			const link = document.createElement('a');
			link.href = image;
			link.download = fileName+'.png';
			link.click();
		  });
		}
	};
	
	sendToSheet=(e)=>{
		let filter = e.target.name;
		if($("input[name='"+filter+"']").prop("checked") == true){
			this.setState({[filter]:true});
		}
	}
	
	render() {
		const {qrName,qrValue} = this.state;
		let href = window.location.href.split('?')[0];
		return (
			<div className="container">
			
			<div className="row justify-content-center go-to-section mt-3 mb-4">
				<div className="col-md-12 text-center"><h4>QR Generator</h4></div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="formGroup mb-4">
						<label>Enter Qr name</label>
						<input type="text" name="qrName" className="form-control" placeholder="Enter Qr name" onChange={this.getValue}></input>
					</div>
					<div className="formGroup mb-4">
						<label>Enter Qr value</label>
						<input type="text" name="qrValue" className="form-control" placeholder="Enter Qr value" onChange={this.getValue}></input>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="formGroup mb-4">
								<label><input className="" name="sendToSheet" type="checkbox" onChange={this.sendToSheet} />  Send to Spreadsheet</label>
							</div>
						</div>
						<div className="col-md-6 text-right">
						<div className="formGroup">
							<button type="submit" className="btn btn-primary" onClick={this.qrGeneratorCode}>Submit</button>
						</div>
						</div>
					</div>
				</div>
			</div>	
				
			{this.state.qrValue ? 
			<div className="modal" id={"qrCodePopup"} role="dialog">
				<div className="modal-dialog modal-lg custom-modal qr-code-modal">
					<div className="modal-content qr-code-modal">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body text-center">
							<div id="qr-code-img" className='rq-code-canvas'>
								<QRCode size={150} value={this.state.qrValue}/>
							</div>
							<div className="qr-code-button">
								<button onClick={()=>this.copyImageToClipboard()} className="btn copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
								<button onClick={()=>this.handleCopyQRCode(this.state.qrName)} className="btn copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			:null}
			</div>
		);
	}
}
