import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin'
import { copyImageToClipboard } from 'copy-image-clipboard'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

export default class WorkFlowBuilder extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			catList:[],
			taskList:[],
			tasks:[],
			csvdata:[],
			listCategories:[],
			listSubCategories:[],
			selectedWorkflow:'',
			file:'',
			taskId:'',
			taskName:'',
			taskStatus:'',
			userId:'',
			uId:'',
			copyQrCode:'',
			facilityId:'',
			workflowUid:'',
			newWorkflowName:'',
			selectedWorkflowName:'',
			workflowSharingCode:'',
			workflowTasks:new Map(),
			wflowTasks:[],
			workflowList:[],
			showUpdate:false,
			showSharing:false,
			showScaner:false,
			decodedResults:[],
			categories:[],
			subcategories:[],
			subcategoriesV2:[],
			subcategoriesV3:[],
			companies:[],
			branches:[],
			shareable:1,
			catId:1,
			subcatId:'',
			addEditCategory:false,
			cusCategoryName:'',
			addEditSubCategory:false,
			createNewWorkflow:false,
			workflowOrder:false,
			updatePos:false,
			selectedListName:'',
			selectedListid:'',
			selectedListCat:'',
			selectedListSubCat:'',
			subcatIdV2:'',
		}
		this.onNewScanResult = this.onNewScanResult.bind(this);
    }
	
	componentDidMount() {
		this.getTaskLists();
		this.getListCategories();
		this.getWorkflowCategories();
		this.getWorkflow();
		this.getCompanies();
		this.loadScript();
	}
	
	loadScript() {
		
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getCompanies=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'companies';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({companies:data});
        })
	}
	
	getTaskLists=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-name';
		let userCompany = localStorage.getItem('userCompany');
		let role = localStorage.getItem('role');
		if(role != 'SuperAdmin'){
			url = ApiUrl+'task-lists/'+userCompany;
		}
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catList:data});
        })
	}
	
	getWorkflowCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-categories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({categories:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategories:data});
        })
	}
	
	getSubCategoriesV2=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategoriesV2:data});
        })
	}
	
	getSubCategoriesV3=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategoriesV3:data});
        })
	}
	
	getTaskList=()=>{
		let ApiUrl = $('#ApiUrl').val();
		//let cat = $('#taskCtWork').val();
		let cat = this.state.selectedListid;
		
		let url = ApiUrl+'tasks/'+cat;
		let data = [];
		
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({taskList:data});
        })
	}
	
	getListCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-categories';
		let userCompany = localStorage.getItem('userCompany');
		let role = localStorage.getItem('role');
		if(role != 'SuperAdmin'){
			url = ApiUrl+'list-categoriesv2/'+userCompany;
		}
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({listCategories:data});
        })
	}
	
	getListSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({listSubCategories:data});
        })
	}
	
	selectListCategory=(event)=>{
		//let selectedListCategoryName = event.target.selectedOptions[0].text;
		let res = event.target.value;
		$('#listSubCt').val('');
		this.setState({taskList:[],selectedListName:''});
		if(res){
			this.setState({selectedListCat:res});
			this.getListSubCategories(res);
		}else{
			this.setState({selectedListCat:''});
		}
	}
	
	selectListSubCategory=(event)=>{
		let res = event.target.value;
		//let selectedSubCategoryName = event.target.selectedOptions[0].text;
		this.setState({taskList:[],selectedListName:''});
		if(res){
			this.setState({selectedListSubCat:res});
		}else{
			this.setState({selectedListSubCat:''});
		}
	}
	
	changeCategory=(event)=>{
		let res = event.target.value;
		let selectedListName = event.target.selectedOptions[0].text;
		this.setState({selectedListid:res,selectedListName,workflowOrder:false});
		let that = this;
		setTimeout(function(){
				that.getTaskList();
		}, 1000);
		
	}

	editTaskInfo=(id)=>{
		this.setState({taskId:id});
		let taskList = this.state.taskList;
		
		taskList.map((value, i) => {
			if(value.id == id){
				this.setState({taskName:value.name,taskStatus:value.status});
			}
		})
		
		$('#editTasks').modal('show');
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditSubCategory'){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditSubCategory:false,cusSubCategoryName:selectedCategoryName});
		}
		
		if(name == 'wEditCategory'){
			this.getSubCategories(res);
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditCategory:false,wcatName:selectedCategoryName});
		}
		
		if(name == 'assignCompany'){
			this.getBranches(res);
		}
	}
	
	getBranches=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'branches-by-company/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({branches:data});
        })
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.setState(({wflowTasks}) => ({
		    wflowTasks: arrayMove(wflowTasks, oldIndex, newIndex),
		}));
	};
	
	onSortEnd2 = ({oldIndex, newIndex}) => {
		this.setState(({workflowList}) => ({
		    workflowList: arrayMove(workflowList, oldIndex, newIndex),
		}));
		this.setState({updatePos:true});
	};
	
	setWorkflowList = (event) => {
		let taskId = parseInt(event.target.id);
		let taskName = event.target.name;
		let workflowId = $('#builderWorkflowOption').val();
		let wflowTasksLength = this.state.wflowTasks.length;
		let workflowTasks = this.state.workflowTasks;
		
		if(event.target.checked){
			this.state.taskList.map((val, i) => {
				if(val.id == taskId){
					val.pos = wflowTasksLength+1;
					workflowTasks.set(taskId,val);
				}
			});
		}else{
			workflowTasks.delete(taskId);
		}
		
		this.setState({workflowTasks:workflowTasks});
		
		let opTasks = Object.fromEntries(workflowTasks.entries());
		let wflowTasks = [];
		
		for (let prop in opTasks) {
			wflowTasks.push(opTasks[prop]);
		}
		wflowTasks = wflowTasks.filter(function(x) {
			 return x !== '';
		});
		
		wflowTasks.sort(function(a, b){
			return parseInt(a.pos)- parseInt(b.pos);
		});
		
		this.setState({wflowTasks:wflowTasks});
		
		if(taskName == 'delete' && this.state.selectedWorkflow != ''){
			this.deleteTask(workflowId,taskId);
		}	
	}
	
	preHandleSubmit=()=>{
		
		let workflowName = $('#workflowName').val();
		let selectedListName = this.state.selectedListName;
		let catId = this.state.catId ? this.state.catId : 1;
		let subcatId = this.state.subcatId ? this.state.subcatId : 0;
		/* if(selectedListName == ''){
			alert('Please select the List name first!');
		} */
		
		this.setState({showUpdate:false,showSharing:false,showScaner:false});
		
		if(workflowName == ''){
			alert('Please enter the Workflow name!');
		}
		
		if(workflowName){
			$('#createWorkflow').modal('hide');
			this.setState({workflowTasks:new Map(),wflowTasks:[],catId,subcatId,workflowName,selectedWorkflowName:workflowName,createNewWorkflow:true});
		}
		

	}
	
	handleSubmit=()=>{
		
		//let workflowName = $('#workflowName').val();
		let workflowName = this.state.workflowName;
		let wflowTasks = this.state.wflowTasks;
		let catId = this.state.catId;
		let subcatId = this.state.subcatId;
		//let assignCompany = this.state.assignCompany;
		let assignBranch = this.state.assignBranch;
		let newArray = [];
		this.state.wflowTasks.map((val, i) => {
			newArray.push(val['id']);
		})
		let ids = newArray.join();
		let user_id = localStorage.getItem('user_id');
		let user_facility_id = localStorage.getItem('user_facility_id');
		let assignCompany = localStorage.getItem('userCompany');
		let uid = ulid();
		
		let datePlusOne = new Date();
		datePlusOne.setDate(datePlusOne.getDate() + 1);
		
		if(workflowName && ids){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-workflow';
			let formData = new FormData();
			formData.append('name', workflowName);
			formData.append('uid', uid);
			formData.append('user_id', user_id);
			formData.append('facility_id', user_facility_id);
			formData.append('category', catId);
			formData.append('subcategory', subcatId);
			formData.append('ids', ids);
			formData.append('datePlusOne', datePlusOne);
			formData.append('company', assignCompany);
			formData.append('branch', assignBranch);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//this.getTaskList();
					this.getWorkflow();
					this.setState({workflowTasks:new Map(),wflowTasks:[],catId:'',subcatId:'',workflowName:'',selectedWorkflowName:'',createNewWorkflow:false,selectedCategoryName:'',selectedSubCategoryName:''});
					alert(response.data);
					$('#workflowName').val('');
					//$('#taskStatus').val('Task');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert the workflow name and the tasks.");
		}

	}
	
	updateWorkflow=()=>{
		
		let workflowId = $('#builderWorkflowOption').val();
		let wflowTasks = this.state.wflowTasks;
		
		let newArray = [];
		this.state.wflowTasks.map((val, i) => {
			newArray.push(val['id']);
		})

		let ids = newArray.join();
		
		if(workflowId && newArray){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-workflow-tasks/'+workflowId;
			let formData = new FormData();
			formData.append('ids', ids);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.updateWorkflowPos(workflowId);
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	getWorkflow=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow';
		let data = [];
		
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({workflowList:data});
			setTimeout(function(){
				//that.getTaskList();
			}, 3000);
        })
	}
	
	updateWorkflowPos=(Id)=>{
		this.setState({workflowTasks:new Map()});
		if(Id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'workflow-tasks/'+Id;
			let that = this;
			let data = [];
			setTimeout(function(){
				let workflowTasks = that.state.workflowTasks;
				let wflowTasks = that.state.wflowTasks;
				
				axios.get(url)
				.then(response => {
					data = response.data;
					
					let wTasks = [];
					data.map((val, i) => {
						workflowTasks.set(val.id,val);
						that.setState({workflowTasks:workflowTasks});
						wTasks.push(val);
					});
					that.setState({wflowTasks:wTasks});
				})
			}, 1000);
		}
	}
	
	selectWorkflow=(event)=>{
		let Id = event.target.value;
		let selectedWorkflowName = Id ? event.target.selectedOptions[0].text : '';
		let workflowList = this.state.workflowList;
		workflowList.map((val, i) => {
			
			if(val.id == Id){
				this.setState({userId:val.user_id,uId:val.uid,facilityId:val.facility_id,workflowSharingCode:val.uid,shareable:val.shareable});
			}
			
		});
		
		this.setState({selectedWorkflowId:Id,selectedWorkflowName,workflowTasks:new Map(),wflowTasks:[]});
		if(Id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'workflow-tasks/'+Id;
			
			
			let that = this;
			let data = [];
			setTimeout(function(){
				let workflowTasks = that.state.workflowTasks;
				let wflowTasks = that.state.wflowTasks;
				axios.get(url)
				.then(response => {
					data = response.data;
					let wTasks = [];
					data.map((val, i) => {
						workflowTasks.set(val.id,val);
						that.setState({workflowTasks:workflowTasks});
						wTasks.push(val);
					});
					
					that.setState({wflowTasks:wTasks});
				})
				
			}, 1000);

			that.setState({createNewWorkflow:true,showUpdate:true, selectedWorkflow:Id,workflowOrder:false});
			//console.log('workflowTasks->',this.state.workflowTasks);
		}else{
			this.setState({showUpdate:false});
		}
	}
	
	filterCategory=(event)=>{
		let catId = event.target.value;
		if(catId){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({selectedCategoryName,catId});
			this.getSubCategories(catId);
			$('#builderWorkflowOption').val('');
		}else{
			this.setState({selectedCategoryName:'',catId:'',selectedSubCategoryName:'',subcatId:''});
		}
	}
	
	filterSubCategory=(event)=>{
		let subcatId = event.target.value;
		if(subcatId){
			let selectedSubCategoryName = event.target.selectedOptions[0].text;
			this.setState({selectedSubCategoryName,subcatId});
			$('#builderWorkflowOption').val('');
		}else{
			this.setState({selectedSubCategoryName:'',subcatId:''});
		}
	}
	
	filterCategoryV2=(event)=>{
		let catIdV2 = event.target.value;
		if(catIdV2){
			let selectedCategoryNameV2 = event.target.selectedOptions[0].text;
			this.setState({selectedCategoryNameV2,catIdV2});
			this.getSubCategoriesV2(catIdV2);
			$('#workflowSubCt').val('');
		}else{
			this.setState({selectedCategoryNameV2:'',catIdV2:'',selectedSubCategoryNameV2:'',subcatId:''});
		}
	}
	
	filterSubCategoryV2=(event)=>{
		let subcatIdV2 = event.target.value;
		if(subcatIdV2){
			let selectedSubCategoryNameV2 = event.target.selectedOptions[0].text;
			this.setState({selectedSubCategoryNameV2,subcatIdV2});
			$('#workflowSubCt').val('');
		}else{
			this.setState({selectedSubCategoryNameV2:'',subcatIdV2:''});
		}
	}
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	deleteTask=(id,taskId)=>{
		if(id && taskId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-workflow-task/'+id;
			let formData = new FormData();
			formData.append('id', id);
			formData.append('taskId', taskId);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	copyWorkflow=()=>{
		
		let workflowName = this.state.newWorkflowName;
		let wflowTasks = this.state.wflowTasks;
		
		/* let newArray = [];
		this.state.wflowTasks.map((val, i) => {
			newArray.push(val['id']);
		})
		let ids = newArray.join(); */
		let user_id = localStorage.getItem('user_id');
		let user_facility_id = localStorage.getItem('user_facility_id');
		let uid = ulid();
		//console.log('ids->',ids);
		if(workflowName && wflowTasks){
			let tasks = JSON.stringify(wflowTasks);
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'import-workflow';
			let formData = new FormData();
			formData.append('name', workflowName);
			formData.append('uid', uid);
			formData.append('user_id', user_id);
			formData.append('facility_id', user_facility_id);
			formData.append('tasks', tasks);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getWorkflow();
					alert(response.data);
					this.setState({newWorkflowName:''});
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please select the category and insert the task name.");
		}
		
	}
	
	saveAsWorkflow=()=>{
		
		let workflowName = this.state.newWorkflowName;
		let wflowTasks = this.state.wflowTasks;
		let user_id = localStorage.getItem('user_id');
		let user_facility_id = localStorage.getItem('user_facility_id');
		let uid = ulid();
		//console.log('ids->',ids);
		if(workflowName && wflowTasks){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'import-workflow';
			let formData = new FormData();
			let tasks = JSON.stringify(wflowTasks);
			formData.append('name', workflowName);
			formData.append('uid', uid);
			formData.append('user_id', user_id);
			formData.append('facility_id', user_facility_id);
			formData.append('tasks', tasks);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getWorkflow();
					alert(response.data);
					this.setState({newWorkflowName:''});
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please select the category and insert the task name.");
		}
		
	}
	
	importBySharingCode=()=>{
		
		let sharingCode = this.state.sharingCode;
		let newWorkflowName = this.state.newWorkflowName;
		
		let workflowId = '';
		this.state.workflowList.map(function(val,i) {
			if(val.uid == sharingCode){
				workflowId = val.id;
			}
		});

		if(workflowId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'workflow-tasks/'+workflowId;
			
			axios.get(url).then(response => {
				let data = response.data;
				let that = this;
				setTimeout(function(){
					let tasks = JSON.stringify(data);
					if(newWorkflowName && tasks){
						
						let ApiUrl = $('#ApiUrl').val();
						let url = ApiUrl+'import-workflow';
						let user_id = localStorage.getItem('user_id');
						let user_facility_id = localStorage.getItem('user_facility_id');
						let uid = ulid();
					
						let formData = new FormData();
						formData.append('name', newWorkflowName);
						formData.append('uid', uid);
						formData.append('user_id', user_id);
						formData.append('facility_id', user_facility_id);
						formData.append('tasks', tasks);
						axios({
							method: 'POST',
							url: url,
							data: formData,
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(response => {
							if(response.data){
								that.getWorkflow();
								alert(response.data);
								that.setState({newWorkflowName:'',workflowUid:'',workflowByQrcode:'',decodedResults:[]});
							}
						}).catch(error => {
							alert('error::'+ error);
						})
						
					}else{
						alert('Data not found!');
					}
				}, 1000);
			})
		}else{
			alert('Sharing code is invalid!');
		}
	}
	
	showHideCode=(action)=>{
		if(action == 'show'){
			this.setState({showSharing:true});
		}else{
			this.setState({showSharing:false});
		}
	}
	
	copySharingCode=()=>{
		let copyText = document.getElementById("workflowSharingCode");
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
	}
	
	copyQrImage=(img)=>{
		let path = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		copyImageToClipboard(path).then(() => {
		  //console.log('Image Copied');
		}).catch((e) => {
		  console.log('Error: ', e.message);
		})
		// Browser
		/* CopyImageClipboard.copyImageToClipboard(path).then(() => {
			console.log('Image Copied')
		}).catch((e) => {
			console.log('Error: ', e.message)
		}) */
	}
	
	saveQrImage=(img,fileName)=>{
		let url = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		var xhr = new XMLHttpRequest();
		xhr.open("GET", url, true);
		xhr.responseType = "blob";
		xhr.onload = function(){
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement('a');
			tag.href = imageUrl;
			tag.download = fileName+'.png';
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		}
		xhr.send();
	}
	
	getQrCode=()=>{
		let copyText = $('#workflowSharingCode').val();
		this.setState({copyQrCode:copyText});
	}
	
	scanCodeByCamera=()=>{
		this.setState({showScaner:true,workflowUid:'',workflowByQrcode:''});
		$('#builderSection').hide();
	}
	
	backToBuilder=()=>{
		this.setState({showScaner:false});
		$('#builderSection').show();
	}
	
	onNewScanResult(decodedText, decodedResult) {
		this.setState((state, props) => {
		  state.decodedResults.push(decodedResult);
		  return state;
		});
		let workflowName = '';
		let workflowList = this.state.workflowList;
		let uid = decodedText.replaceAll("'","");
		if(uid){
			workflowList.map((val, i) => {
				if(val.uid == uid){
					workflowName = val.name;
				}
			});
			this.setState({workflowUid:uid, workflowByQrcode:workflowName});
		}
		//this.html5QrcodeScanner.pause(true);
    }
	
	addToWorkflowList=()=>{
		let workflowUid = this.state.workflowUid;
		let currentDate = new Date().toLocaleString("en-US").replace(',','');
		let workflowNewName = this.state.workflowByQrcode;
		let newName = workflowNewName+'-QR '+moment(currentDate).format('MMDDYYYY');
	
		this.setState({sharingCode:workflowUid,newWorkflowName:newName});
		let that = this;
		setTimeout(function(){
			that.importBySharingCode();
			$("button:contains('Stop Scanning')" ).trigger("click");
		}, 1000);
	}
	
	addCategory=() => {
		this.setState({addEditCategory:true,cusCategoryName:'',editcatId:''});
	}
	
	addSubCategory=() => {
		this.setState({addEditSubCategory:true,cusCategoryName:'',editcatId:''});
	}
	
	editCategory=() => {
		let catName = this.state.wcatName;
		this.setState({addEditCategory:true,cusCategoryName:catName,editcatId:this.state.wEditCategory});
	}
	
	editSubCategory=() => {
		let catName = this.state.cusSubCategoryName;
		this.setState({addEditSubCategory:true,cusSubCategoryName:catName,editcatId:this.state.wEditSubCategory});
	}
	
	addUpdateCategory=() => {
		
		let catName = this.state.cusCategoryName;
		let wEditCategory = this.state.wEditCategory;
		let catSubName = this.state.cusSubCategoryName;
		let editcatId = this.state.editcatId;
		let user_id = localStorage.getItem('user_id');
		if(catName || catSubName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'manage-categories';
			let formData = new FormData();
			formData.append('id', editcatId);
			if(catSubName){
				formData.append('parent_id', wEditCategory);
				formData.append('name', catSubName);
			}else{
				formData.append('name', catName);
			}
			formData.append('user_id', user_id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getWorkflowCategories();
					this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wEditCategory:'',addEditSubCategory:false});
					$('.w-edit-category').val('');
					alert(response.data);
					
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	selectWorkflowCategory=(event)=>{
		let selectedCategoryNameV2 = event.target.selectedOptions[0].text;
		let res = event.target.value;
		if(res){
			this.setState({selectedListCatV2:res,selectedCategoryNameV2});
			this.getSubCategories(res);
		}else{
			this.setState({selectedListSubCatV2:'',selectedListCatV2:'',selectedCategoryNameV2:''});
		}
	}
	
	selectWorkflowSubCategory=(event)=>{
		let res = event.target.value;
		let selectedSubCategoryNameV2 = event.target.selectedOptions[0].text;
		if(res){
			this.setState({selectedListSubCatV2:res,selectedSubCategoryNameV2});
		}else{
			this.setState({selectedListSubCatV2:'',selectedSubCategoryNameV2:''});
		}
	}
	
	changeWorkflow=(event)=>{
		let res = event.target.value;
		let workflowName = event.target.selectedOptions[0].text;
		this.getTaskList();
		if(res){
			this.setState({selectedWorkflowIdV2:res,selectedWorkflowV2:workflowName});
		}else{
			this.setState({selectedWorkflowIdV2:'',selectedWorkflowV2:''});
		}
	}
	
	selectWorkflowCategoryV3=(event)=>{
		let selectedCategoryNameV3 = event.target.selectedOptions[0].text;
		let res = event.target.value;
		if(res){
			this.setState({selectedWorkflowCatV3:res,selectedCategoryNameV3});
			this.getSubCategoriesV3(res);
		}else{
			this.setState({selectedWorkflowCatV3:'',selectedWorkflowSubCatV3:'',selectedCategoryNameV3:'',selectedSubCategoryNameV3:''});
		}
	}
	
	selectListSubCategoryV3=(event)=>{
		let selectedSubCategoryNameV3 = event.target.selectedOptions[0].text;
		let res = event.target.value;
		if(res){
			this.setState({selectedWorkflowSubCatV3:res,selectedSubCategoryNameV3});
		}else{
			this.setState({selectedWorkflowSubCatV3:'',selectedSubCategoryNameV3:''});
		}
	}
	
	updateCategorization=()=>{
		let selectedWorkflowIdV2 = this.state.selectedWorkflowIdV2;
		let selectedWorkflowCatV3 = this.state.selectedWorkflowCatV3;
		let selectedWorkflowSubCatV3 = this.state.selectedWorkflowSubCatV3 ? this.state.selectedWorkflowSubCatV3 : 0;
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-workflow-category';
		
		let formData = new FormData();
		formData.append('id', selectedWorkflowIdV2);
		formData.append('category', selectedWorkflowCatV3);
		formData.append('subcategory', selectedWorkflowSubCatV3);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
			$('#changeCategorization').modal('hide');
			alert('The Workflow updated successfully!');
			this.setState({selectedWorkflowIdV2:'',selectedWorkflowV2:'',selectedWorkflowSubCatV3:'',selectedWorkflowCatV3:'',selectedCategoryNameV3:'',selectedWorkflowSubCatV3:'',selectedSubCategoryNameV3:'',selectedWorkflowCatV2:'',selectedCategoryNameV2:'',selectedWorkflowSubCatV2:'',selectedSubCategoryNameV2:'',selectedWorkflowNameV2:''});
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	workflowsListOrder=()=>{
		$('#taskCt').val('')
		this.setState({workflowOrder:true});
	}
	
	updateTaskPosition=()=>{
		let newArray = [];
		//console.log('taskList->',this.state.taskList);
		this.state.workflowList.map((val, i) => {
			let j = i+1;
			newArray[i] = {'id':val.id,'pos':j};
		})
		if(newArray.length > 0){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-workflow-pos';
			axios({
				method: 'POST',
				url: url,
				data: newArray,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.setState({updatePos:false});
				alert('The Workflows order updated successfully!');
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteCategory=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-workflow-category/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getWorkflowCategories();
					this.setState({wEditCategory:'',wEditSubCategory:''});
					alert('The Workflow category delete successfully!');
				}else{
					alert("We can't delete category unless all Workflows are Moved or Deleted from the category.");
				}

			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select List Category!');
		}
	}
	
	deleteSubCategory=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-workflow-subcategory/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getSubCategories(this.state.wEditCategory);
					this.setState({wEditSubCategory:''});
					alert('The Workflow subcategory delete successfully!');
				}else{
					alert("We can't delete subcategory unless all Workflows are Moved or Deleted from the subcategory.");
				}
				
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select List Category!');
		}
	}
	
	render() {
		
		const {selectedWorkflow,workflowList,listCategories,listSubCategories,catList,taskList,workflowTasks,wflowTasks,categories,subcategories,subcategoriesV2,subcategoriesV3,catId,subcatId,catIdV2,subcatIdV2,addEditCategory,cusCategoryName,addEditSubCategory,cusSubCategoryName,selectedListName,createNewWorkflow,selectedListid,selectedListCat,selectedListSubCat,workflowOrder,updatePos,companies,branches} = this.state;
		//console.log('workflowList->',workflowList);
		//console.log('catList->',catList);
		
		let companyOption = companies.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let branchOption = branches.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let categoriesHtml = categories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoriesHtml = subcategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoriesHtmlV2 = subcategoriesV2.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoriesHtmlV3 = subcategoriesV3.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editCategoryOption = categories.map(function(val,i) {
			if(val.id != 1){
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			}
		})
		
		let categoryOption = listCategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoryOption = listSubCategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let href = window.location.href.split('?')[0];
		let catOption = catList.map(function(val,i) {
			
			if(selectedListSubCat && val['category_id'] == selectedListCat && selectedListSubCat == val['subcategory_id']){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
				
			}else if(selectedListSubCat == '' && val['category_id'] == selectedListCat){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}else if(selectedListSubCat == '' && selectedListCat == ''){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
			
		})
		let role = localStorage.getItem('role');
		let userId = localStorage.getItem('user_id');
		let workflowOption = workflowList.map(function(val,i) {
			
			if(catId && catId != val.category){
				return false;
			}
			
			if(subcatId && subcatId != val.subcategory){
				return false;
			}
			if(role == 'OA' && val.user_id == userId){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}else if(role != 'OA'){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
		})
		
		let workflowOptionV2 = workflowList.map(function(val,i) {
			
			if(catIdV2 && catIdV2 != val.category){
				return false;
			}
			
			if(subcatIdV2 && subcatIdV2 != val.subcategory){
				return false;
			}
			
			if(subcatIdV2 == '' && val.subcategory > 0){
				return false;
			}
			
			if(role == 'OA' && val.user_id == userId){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}else if(role != 'OA'){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
		})
		
		let category = $('#taskCtWork').val();
		
		let that = this;
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem = sortableElement(({value}) => {
			let className = 'light-yellow';
			if(value.status == 'Task'){
				className = 'light-green';
			}else if(value.status == 'Subtask'){
				className = 'light-blue';
			}
			
			return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><input className="delete-checkbox" type="checkbox" name='delete' id={value.id} checked={workflowTasks.get(value.id) ? true:false} onChange={this.setWorkflowList}/><DragHandle /><span className="input-title task-input-4">{value.name}</span>
			</div>);
		});
		
		const DragHandle2 = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem2 = sortableElement(({value}) => {
			//console.log('value->',value);
			let text = value.name;
			let count = 45;
			//let textLimit = text.slice(0, count) + (text.length > count ? "..." : "");
			let textLimit = text;
			
			if(subcatId && value.category == catId && subcatId == value.subcategory){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else if(subcatId == '' && value.category == catId){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else if(subcatId == '' && catId == ''){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else{
				return (<div id={value.id} className={'field-div disablelist'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}
			
			//return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
		});
		
		const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		}); 
		let currentDate = new Date().toLocaleString("en-US").replace(',','');
		
		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						  <span></span>
						</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							
							<div className="left-link-top-box">
								<a href="#" className="btn btn-top-header-link" data-toggle="modal" data-target="#createWorkflow" data-dismiss="modal">Create New Workflow</a>
							</div>
							
							
							<div className="left-link-box-2">
								<h6 className="page-title">Select an Existing Workflow</h6>
							
								<div className="input-group mb-3">
									{categoriesHtml ?
										<select id='filterCategory' className="form-control" name="filterCategory" onChange={this.filterCategory}>
											<option value=''>Select Workflow Category</option>
											{categoriesHtml}
										</select>
									:null}
								</div>
								{ subcategoriesHtml.length > 0 && catId ?
								<div className="input-group mb-3">
									<select id='filterSubCategory' className="form-control" name="filterSubCategory" onChange={this.filterSubCategory}>
										<option value=''>Select Workflow Subcategory</option>
										{subcategoriesHtml}
									</select>
									
								</div>
								:null}
								<div className="input-group mb-3">
									<select id='builderWorkflowOption' value={selectedWorkflow} className="form-control" name="workflowOption" onChange={this.selectWorkflow}>
										<option value={''}>Select Existing Workflow</option>
										{workflowOption}
									</select>
								</div> 
								
							</div>
							
							<div className="left-link-box-2">
								<h6 className="page-title">Select Task List To Build Workflow</h6>
								
								<div className="input-group">
									<div className="input-group mb-3">
										<select id='listCt' className="form-control" name="listCt" value={this.state.selectedListCat} onChange={this.selectListCategory}>
										<option value="">Select List Category</option>
										{categoryOption}
										</select>
									</div>
									
									<div className="input-group mb-3">
										<select className="form-control" name="listSubCt" value={this.state.selectedListSubCat} onChange={this.selectListSubCategory}>
										<option value="">Select List Subcategory</option>
										{subcategoryOption}
										</select>
									</div> 
									
								</div>
						
								<div className="input-group mb-3">
									{ catOption ?
									<select id='taskCtWork' className="form-control" name="taskCtWork" value={selectedListid} onChange={this.changeCategory}>
										<option value="">Select Task List Name</option>
										{catOption}
									</select>
									:null}
								</div>
							</div>
							
							
							<div className="left-link-top-box left-link-box-2">
								<h6 className="page-title">Workflow Management</h6>
								<a href="#" className="btn btn-top-header-link mt-2" data-toggle="modal" data-target="#customizeCategory" data-dismiss="modal">Manage Workflow Categories</a>
								
								<a href="#" className="btn btn-top-header-link mt-2" data-toggle="modal" data-target="#changeCategorization" data-dismiss="modal">Change Workflow Categorization</a>
								
								<a className="btn btn-top-header-link mt-2" onClick={()=>this.workflowsListOrder()}>Change Workflow Order</a>
							</div>
							
							
							
						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="status-list-workflow">
					{this.state.showScaner ? 
					<div id="scanerSection" className="scanerDiv">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4><button className="sharing-code-btn" type="button" onClick={()=>this.backToBuilder()}><i className="fa fa-arrow-left" aria-hidden="true"></i></button> QR Scaner </h4>
							</div>
						</div>
						<Html5QrcodePlugin 
						fps={10}
						qrbox={250}
						disableFlip={false}
						qrCodeSuccessCallback={this.onNewScanResult}/>
						<ResultContainerPlugin results={this.state.decodedResults} />
						{ this.state.workflowByQrcode != '' && this.state.workflowUid != '' ? 
							<div className="add-to-list">
							<h5>{this.state.workflowByQrcode}</h5>
							<button type="button" onClick={()=>this.addToWorkflowList()} className="btn btn-info mr-1" data-dismiss="modal">Add to Workflow list</button>
							</div>
						:null}
					</div>
					:null}
					<div id="builderSection" className="builder-section">
						<div className="row">
							<div className="col-md-12 mb-2">
								<h4>Workflow Builder</h4>
							</div>
						</div>
						
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6 cat-heading">
								{this.state.selectedCategoryName ? 
								<span>{this.state.selectedCategoryName}</span>
								:null}
								
								{this.state.selectedSubCategoryName ? 
								<span> / {this.state.selectedSubCategoryName}</span>
								:null}
								</div>
								{!workflowOrder && selectedWorkflow ? 
								<div className="col-md-6">
									<select id='builderWorkflowOption' value={selectedWorkflow} className="form-control" name="workflowOption" onChange={this.selectWorkflow}>
										<option value={''}>Select Existing Workflow</option>
										{workflowOption}
									</select>
								</div>
								:null}
								
							</div>
						</div>
						
						{!workflowOrder && createNewWorkflow && taskList ?
						<div className="main-section-m mt-4">
							<div className="row">
								<div className="col-md-6">
								{selectedListName ? 
									<div className="tasks-header-div">
										<p className="task-list-label">"{selectedListName}" Task List</p>
										<div className="tasks-table-div-2">
											{taskList.map((value, index) => (
												<div id={value.id} className={'task-div'}>
													<input type="checkbox" name={value.id} id={value.id} checked={workflowTasks.get(value.id) ? true:false} onChange={this.setWorkflowList}/>
													<span className="input-title task-input-2">{value.name}</span>
													<span className="input-status">{value.status}</span>
												</div>
											))}
											
										</div>
									</div>
								:
									<div className="tasks-header-div text-center mt-5">
									<p className="pt-5 text-secondary">Please Selete Task List to load tasks!</p>
									</div>
								}
								</div>
								<div className="col-md-6">
									<div className="row">
										<div className="col-md-5">
										{this.state.selectedWorkflowName}
										</div>
										{this.state.selectedWorkflowName && this.state.selectedWorkflowId ? 
										<div className="col-7">
											{this.state.showSharing && this.state.shareable == 'Y' ?
												<div className="row">
													<div className="col-md-1 pd-0"><button className="btn sharing-code-btn" type="button" onClick={()=>this.showHideCode('hide')}><img src={href+'/Icon-View.png'} alt="View" width="26" height="14" /></button></div>
													
													<div className="col-md-8 code-copy pd-0"><input id="workflowSharingCode" className="form-control" name="workflowSharingCode" value={this.state.workflowSharingCode} type="text" onChange={this.getValue}/></div>
													<div className="col-md-2 pd-0">
													<button className="btn copy-code-btn" type="button" onClick={()=>this.copySharingCode()}><i className="fa fa-copy" aria-hidden="true"></i></button>
													<button onClick={this.getQrCode} data-toggle="modal" data-target="#qrCodePopup" className="btn qr-code-btn" type="button"><img src={href+'/Icon-QR.png'} alt="OR" width="18" height="18" /></button>
													</div>
												</div>
											:
												<div className="row"><div className="col-md-1 pd-0"><button className="btn sharing-code-btn" type="button" onClick={()=>this.showHideCode('show')}><img src={href+'/icon-hide.png'} alt="View" width="26" height="18" /></button></div>
												<div className="col-md-10 pd-1">
													<button className="btn sharing-code-btn" type="button" >
													{this.state.shareable == 'Y' ?
														<img src={href+'/icon-unlock.png'} alt="Share" width="15" height="18" />
													:
														<img src={href+'/lock.png'} alt="Share" width="15" height="18" />
													}
													</button>
												</div>
												</div>
											}
										</div>
										:null}
									</div>
									<div className="row">
										<div className="col-md-12">
										<div className="workflow-div">
											{wflowTasks ?
											<div className="workflow-select-list">
												<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
													{wflowTasks.map((value, index) => (
													  <SortableItem key={index} index={index} value={value} /> 
													))}
												</SortableContainer>
												
											</div>
											:null}
										</div>
										{ this.state.showUpdate ? 
											<div className="workflow-save">
												<button className="btn btn-primary mr-1" type="button" onClick={()=>this.updateWorkflow()}>Update Workflow</button>
												<button className="btn btn-primary mr-1" data-toggle="modal" data-target="#copyWorkflow" type="button" >Copy Workflow</button>
												<button className="btn btn-primary" data-toggle="modal" data-target="#saveAsWorkflow" type="button" >Save as</button>
												<div className="modal" id={"copyWorkflow"} role="dialog">
													<div className="modal-dialog modal-lg custom-modal mds-description-modal">
														<div className="modal-content">
														  <div className="modal-header">
															<h5 className="modal-title">Workflow New Name</h5>
															<button type="button" className="close" data-dismiss="modal">&times;</button>
														  </div>
														  <div className="modal-body">
															<input className="form-control" name="newWorkflowName" type="text" value={this.state.newWorkflowName} onChange={this.getValue} />
														  </div>
														  <div className="modal-footer">
																<div className="popup-btn-com">
																	<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
																	<button type="button" onClick={()=>this.copyWorkflow()} className="btn btn-info float-right mr-1" data-dismiss="modal">Save</button>
																</div>
														  </div>
														</div>
													</div>
												</div>
												<div className="modal" id={"saveAsWorkflow"} role="dialog">
													<div className="modal-dialog modal-lg custom-modal mds-description-modal">
														<div className="modal-content">
														  <div className="modal-header">
															<h5 className="modal-title">Workflow New Name</h5>
															<button type="button" className="close" data-dismiss="modal">&times;</button>
														  </div>
														  <div className="modal-body">
															<input className="form-control" name="newWorkflowName" type="text" value={this.state.newWorkflowName} onChange={this.getValue} />
														  </div>
														  <div className="modal-footer">
																<div className="popup-btn-com">
																	<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
																	<button type="button" onClick={()=>this.saveAsWorkflow()} className="btn btn-info float-right mr-1" data-dismiss="modal">Save</button>
																</div>
														  </div>
														</div>
													</div>
												</div>
											</div>
										:
											
											<div className="workflow-save text-right">
											{/* <input id="workflowName2" className="form-control" name="workflowName" type="text" placeholder="Create New Workflow" /> */}
												<button className="btn btn-primary" type="button" onClick={()=>this.handleSubmit()}>Save</button>
											</div>
											
										}
										{/* <button className="import-btn" data-toggle="modal" data-target="#importWorkflow" type="button" ><i className="fa fa-upload" aria-hidden="true"></i></button> */}
										
										<div className="modal" id={"importWorkflow"} role="dialog">
											<div className="modal-dialog modal-lg custom-modal mds-description-modal">
												<div className="modal-content">
												  <div className="modal-header">
													<h5 className="modal-title">Import Workflow</h5>
													<button type="button" className="close" data-dismiss="modal">&times;</button>
												  </div>
												  <div className="modal-body">
												  
													<label className="save-lable">Insert Sharing Code:</label>
													<input className="form-control" name="sharingCode" type="text" value={this.state.sharingCode} onChange={this.getValue} />
													<label className="save-lable">New Workflow Name:</label>
													<input className="form-control" name="newWorkflowName" type="text" value={this.state.newWorkflowName} onChange={this.getValue} />
													</div>
												  <div className="modal-footer">
														<div className="popup-btn-com">
															<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
															<button type="button" onClick={()=>this.importBySharingCode()} className="btn btn-info float-right mr-1" data-dismiss="modal">Import</button>
															<button type="button" data-dismiss="modal" onClick={()=>this.scanCodeByCamera()} className="copy-code-btn mr-1"><img className="scaner-btn" src={href+'/scanner.png'} /></button>
														</div>
												  </div>
												</div>
											</div>
										</div>
										</div>
										
										<div className="modal" id={"qrCodePopup"} role="dialog">
											<div className="modal-dialog modal-lg custom-modal qr-code-modal">
												<div className="modal-content qr-code-modal">
													<div className="modal-header">
														<button type="button" className="close" data-dismiss="modal">&times;</button>
													</div>
													<div className="modal-body">
														<img src={"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='"+this.state.copyQrCode+"'"} alt="QR" width="150" height="150" />
														<div className="qr-code-des">
															<b className="mr-3">{this.state.selectedWorkflowName}</b>
															<button onClick={()=>this.copyQrImage(this.state.copyQrCode)} className="copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
															<button onClick={()=>this.saveQrImage(this.state.copyQrCode,this.state.selectedWorkflowName)} className="copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						:null}	
						
						{workflowOrder ? 
						<div className="col-md-12 tasks-table-div-3">
							{ workflowList.length > 0 ?
							<SortableContainer onSortEnd={this.onSortEnd2} useDragHandle>
								{workflowList.map((value, index) => (
								  <SortableItem2 key={index} index={index} value={value} /> 
								))}
							</SortableContainer>
							:null }
						</div>
						:null}
						{this.state.updatePos ?
							<button onClick={()=>this.updateTaskPosition()} type="button" className="btn btn-primary float-right mr-1" >Update Order of Tasks</button>
						:null}
					</div>
				</div>
				
				
				<div className="modal" id={"changeCategorization"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Workflow Categorization</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								<div className="panel-btn-v2">
									<div className="input-group mb-3">
										<select id='workflowCt' className="form-control unselect" name="workflowCt" value={this.state.catIdV2} onChange={this.filterCategoryV2}>
										<option value="">Select Current Workflow Category</option>
										{categoriesHtml}
										</select>
									</div>
									{ subcategoriesHtmlV2.length > 0 && catIdV2 ?
									<div className="input-group mb-3">
										<select id="workflowSubCt" className="form-control unselect" name="workflowSubCt" value={this.state.subcatIdV2} onChange={this.filterSubCategoryV2}>
										<option value="">Select Current Workflow Subcategory</option>
										{subcategoriesHtmlV2}
										</select>
									</div>
									:null}
									
									<div className="input-group">
										<select id='changeWorkflow' className="form-control unselect" name="changeWorkflow" onChange={this.changeWorkflow}>
										<option value="">Select Workflow</option>
										{workflowOptionV2}
										</select>
									</div>
								</div>
								<div className="change-task-cat">
									<label>
									{this.state.selectedCategoryNameV2 ? this.state.selectedCategoryNameV2 : ''} 
									
									{this.state.selectedSubCategoryNameV2 ? ' / '+this.state.selectedSubCategoryNameV2 : ''}
									
									{this.state.selectedWorkflowV2 ? ' / '+this.state.selectedWorkflowV2 : ''}
									</label>
									
								</div>
								{this.state.selectedWorkflowV2 ?
								<div className="panel-btn-v2">
									<h6 className="modal-title mb-3">Select New Categorization for Workflow</h6>
									<div className="input-group mb-3">
										<select className="form-control unselect" name="workflowCtV3" onChange={this.selectWorkflowCategoryV3}>
										<option value="">Select List Category</option>
										{categoriesHtml}
										</select>
									</div>
									<div className="input-group mb-3">
										<select className="form-control unselect" name="workflowSubCtV3" onChange={this.selectListSubCategoryV3}>
										<option value="">Select List Subcategory</option>
										{subcategoriesHtmlV3}
										</select>
									</div>
									<button onClick={()=>this.updateCategorization()} type="button" className="btn btn-primary float-right mr-1" >Update</button>
								</div>
								:null}
							</div>	
						</div>
					</div>
				</div>
				
				<div className="modal" id={"createWorkflow"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Create a New Workflow</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								
								<label>Categories:</label>
								<div>
								<select className="form-control w-edit-category" name="filterCategory" value={this.state.catId} onChange={this.filterCategory}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								</div>
								
								{ this.state.subcategories.length > 0 ?
								<div className="new-category-input">
									<label>Sub categories for {this.state.selectedCategoryName} :</label>
									<div>
									<select className="form-control w-edit-category" name="filterSubCategory" value={this.state.subcatId} onChange={this.filterSubCategory}>
										<option value={''}>Select Subcategory</option>
										{subcategoriesHtml}
									</select>
									</div>
									
								</div>
								:null}
								
								{/*<div>
								<label> Company: </label>
								<select className="form-control" name="assignCompany" onChange={this.getValue}>
									<option value={''}>Select Company</option>
									{companyOption}
								</select>
								</div>
								
								{branches.length > 0 ? 
								<div>
								<label> Branch: </label>
								<select className="form-control" name="assignBranch" onChange={this.getValue}>
									<option value={''}>Select Branch</option>
									{branchOption}
								</select>
								</div>
								:null}*/}
								
								{/* <div className="new-category-input">
									<label>Select Task List</label>
									{ catOption ?
										<select id='taskCtWork' className="form-control" name="taskCtWork" value={selectedListid} onChange={this.changeCategory}>
											<option value="">Select Task List Name</option>
											{catOption}
										</select>
									:null}
								</div> */}
								
								
								<div className="new-category-input">
									<label>New Workflow name:</label>
									<input id="workflowName" className="form-control" name="workflowName" type="text" placeholder="Workflow Name" />
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.preHandleSubmit()} type="button" className="btn btn-info float-right mr-1" >Create New Workflow</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"customizeCategory"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Workflow Category Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								
								<label>Categories:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								<span className="w-edit-span" >
								<button onClick={()=>this.addCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
								
								{this.state.wEditCategory ?
								<button onClick={()=>this.editCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
								:null}
								{this.state.wEditCategory ?
								<button onClick={() => { if (window.confirm('Are you sure you want to delete this category?')) this.deleteCategory(this.state.wEditCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
								:null}
								</span>
								</div>
								{ addEditCategory ?
								<div className="new-category-input">
									{/* cusCategoryName ?
									<label>Edit category name:</label>
									:
									<label>Add category name:</label>
									 */}
									<label>Add&Edit category name:</label>
									<input className="form-control" name="cusCategoryName" value={cusCategoryName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
								{ this.state.wEditCategory ?
								<div className="new-category-input">
									<label>Sub categories for {this.state.wcatName} :</label>
									<div>
									<select className="form-control w-edit-category" name="wEditSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{subcategoriesHtml}
									</select>
									<span className="w-edit-span" >
									<button onClick={()=>this.addSubCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									{this.state.wEditSubCategory ?
									<button onClick={()=>this.editSubCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.wEditSubCategory ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this subcategory?')) this.deleteSubCategory(this.state.wEditSubCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									</div>
									{ addEditSubCategory ?
									<div className="new-category-input">
										{/* cusSubCategoryName ?
										<label>Edit subcategory name:</label>
										:
										<label>Add subcategory name:</label>
										 */}
										 <label>Add&Edit subcategory name:</label>
										<input className="form-control" name="cusSubCategoryName" value={cusSubCategoryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
								</div>
								:null}
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateCategory()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
		);
	}
}
